import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import CtaSection from "../components/CtaSection";
import TestimonialSection from "../components/TestimonialSection";
import { CheckList, CheckListItem } from "../components/CheckList";
import TwoColumnSection from "../components/TwoColumnSection";

const Page = ({ data }) => (
	<Layout pageMeta={{ title: "No-code email editor" }}>
		<div>
			<section className="aboveFold">
				<div className="container">
					<h1>
						<div className="text-xsmall uptitle mb-20 text-light">
							No-code email editor
						</div>
						<div className="maxWidth-750">
							Create responsive, well-tested email templates without code
						</div>
					</h1>

					<p className="subtitle mb-70">
						Sidemail no-code editor is the simplest way to craft a
						professionally-looking, responsive email templates that won't break
						in Outlook. Just upload a logo, pick brand colors, and you're ready
						to start sending in style.
					</p>

					<div className="videoContainer">
						<video loop autoPlay playsInline muted height={300}>
							<source src="/assets/editor.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
			</section>

			<section id="premade-templates" className="container">
				<h2>Jump start email integration with pre‑made email templates</h2>
				<p>
					To give you the best starting point, we researched hundreds of emails
					and crafted a well-spelled email for every occasion. And, if you need
					it, you can modify every bit of it.{" "}
					<a href="/transactional-email-templates/">
						Browse all email templates
					</a>
					.
				</p>
				<div style={{ margin: "0 auto", maxWidth: 800 }}>
					<ul className="box">
						<li>Account activation</li>
						<li>Receipt</li>
						<li>Message notification</li>
						<li>Canceled subscription</li>
						<li>Usage warning</li>
						<li>Sign in alert</li>
						<li>Single sign-on</li>
						<li>Welcome</li>
						<li>Billing issue</li>
						<li>Trial expired</li>
						<li>Product feedback</li>
						<li>Password reset</li>
					</ul>
				</div>
			</section>

			<section className="container">
				<div className="twoColumns">
					<div className="twoColumns-text">
						<h2>Deliver in style with perfected email layout</h2>

						<p className="text">
							We noticed that emails from the top companies have a similar
							layout, and follow the same rules, so, we reviewed hundreds of
							emails, and come up with the perfect design, which{" "}
							<b>you can customize to fit your brand in a couple of clicks</b>.
						</p>

						<CheckList>
							<CheckListItem>
								Completely white-label—upload your logo and match your brand
								colors.
							</CheckListItem>

							<CheckListItem>
								Looks great on mobile and is responsive. With more than 50%
								email opens coming from mobile, this is a necessity.
							</CheckListItem>

							<CheckListItem>
								It comes with built-in dark mode support. We did extensive
								hands-on testing in all email clients,{" "}
								<Link to="/articles/dark-mode-in-html-email/">
									read more here
								</Link>
								.
							</CheckListItem>
						</CheckList>
					</div>

					<div className="twoColumns-imageWrapper">
						<div className="twoColumns-imageWrapperInner">
							<Img fluid={data.templateDesignImage.childImageSharp.fluid} />
						</div>
					</div>
				</div>
			</section>

			<TestimonialSection />

			<section className="container">
				<TwoColumnSection
					title="No needless complexity"
					text="We abstracted all the code that makes a rock-solid email behind a nice looking UI from which you assemble your reusable email template like a Lego. All without opening a single Stack Overflow tab."
					list={[
						"No clutter, just the essentials: paragraphs, titles, links, buttons, images, tables, lists, and a few more.",
						"Inspired with the idea of convention over configuration, the no-code editor is a productivity booster that doesn't get in your way.",
					]}
					img={data.templateElementsImage.childImageSharp.fluid}
				/>
			</section>

			<section
				id="email-client-support"
				className="container"
				style={{
					maxWidth: 600,
					paddingTop: 70,
					paddingBottom: 70,
					marginLeft: "auto",
					marginRight: "auto",
				}}>
				<div
					style={{
						textAlign: "center",
					}}>
					<h2>Broken email sucks</h2>

					<p className="text">
						Noone enjoys debugging broken emails in Outlook when you could be
						doing many more interesting things. Well, good news, you won't have
						to! We continuously test emails in all major email clients to ensure
						a consistently good-looking result in all environments.
					</p>
				</div>

				<div className="box">
					<div>
						<div className="textLabel" style={{ marginBottom: 20 }}>
							Consistent in every email client
						</div>

						<CheckList>
							<CheckListItem>
								Mobile: Gmail, iOS 9.3+, Android 4.4+, ...
							</CheckListItem>

							<CheckListItem>
								Web: Gmail, Outlook.com, Yahoo! Mail, AOL, ...
							</CheckListItem>

							<CheckListItem>
								Desktop: Apple Mail, Windows 10 Mail, Outlook, ...
							</CheckListItem>
						</CheckList>
					</div>
				</div>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Developer experience is one of our top features"
					text="So, how do I send this email template via API, again? No need to break the focus, Sidemail automatically generates working code snippets for all your email templates."
					list={[
						"Pre-generated code snippets include: Node.js, Ruby, PHP, Python, cURL and a generic HTTP request.",
						<>
							Our docs have well-readable guides to which you can peak at any
							time.{" "}
							<Link to="/docs/email-sending-quickstart">
								See how to send email templates via API guide
							</Link>
							.
						</>,
					]}
					img={data.preGeneratedCodeImage.childImageSharp.fluid}
				/>
			</section>

			<section className="moreFeaturesSection container">
				<ul>
					<li>
						<h4>Variables</h4>
						<p>
							Add recipient-specific information to your email when making send
							email API request. Useful for personalized URLs, text, and more.
						</p>
					</li>

					<li>
						<h4>Easy management</h4>
						<p>
							Create an email template. Edit content. Save. Your repository
							stays lean even with dozens of templates in usage.
						</p>
					</li>

					<li>
						<h4>Device preview</h4>
						<p>
							Be sure your email will look gorgeous on every device. See how it
							looks by switching the desktop/mobile preview.
						</p>
					</li>
				</ul>
			</section>

			<CtaSection />
		</div>
	</Layout>
);

export default Page;

export const query = graphql`
	query {
		templateDesignImage: file(
			relativePath: { eq: "sidemail-public--templates-design.png" }
		) {
			...twoColumnsImage
		}
		templateElementsImage: file(
			relativePath: { eq: "sidemail-public--templates-elements.png" }
		) {
			...twoColumnsImage
		}
		preGeneratedCodeImage: file(
			relativePath: { eq: "sidemail-public--templates-code.png" }
		) {
			...twoColumnsImage
		}
	}
`;
