import React from "react";

import IconArrowRight from "../images/inlinesvgs/icons/arrow-right.svg";

const CtaSection = () => {
	return (
		<div className="container">
			<section className="cta">
				<div>
					<h2>Sending in minutes</h2>

					<p className="text-large itemCenter mb-50">
						Try Sidemail free for 7 days. No credit card required.
					</p>

					<a
						href="https://client.sidemail.io/register"
						className="button button--bright button-large button--arrowAnimated mb-50">
						Start trial
						<IconArrowRight className="u-ml1" width={18} height={18} />
					</a>
				</div>
			</section>
		</div>
	);
};

export default CtaSection;
