import React from "react";
import cn from "classnames";

import CheckMarkIcon from "../images/inlinesvgs/icon-checkmark.svg";

export const CheckList = ({ children, className, ...props }) => {
	return (
		<ul className={cn("checkList", className)} {...props}>
			{children}
		</ul>
	);
};

export const CheckListItem = ({ children, IconComponent = CheckMarkIcon }) => {
	return (
		<li>
			<IconComponent />
			{children}
		</li>
	);
};
