import React from "react";

const TestimonialSection = () => {
	return (
		<section id="testimonial" className="container">
			<div className="testimonialBox">
				<h3 className="textLabel" style={{ marginBottom: 15 }}>
					Stand out in any inbox:
				</h3>
				<p>
					<strong>...“I really wanted it to look gorgeous“...</strong>
				</p>
				<p>
					“I wanted something a bit easier so I didn't have to do all the
					template legwork, and this project, I really wanted to look
					gorgeous“...“I spent a couple of hours standing up some html templates
					in their editor. From there, it's as easy as calling up the template
					name and passing in some variables and everything sorta just works.“ –
					Jimmy, Repobox founder
				</p>
			</div>
		</section>
	);
};

export default TestimonialSection;
