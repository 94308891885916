import React from "react";
import Img from "gatsby-image";

import { CheckList, CheckListItem } from "../components/CheckList";

const TwoColumnSection = ({
	title,
	text,
	list = [],
	img,
	customVisual,
	cta,
}) => {
	return (
		<div className="twoColumns">
			<div className="twoColumns-text">
				<h2 className="title-large">{title}</h2>

				{typeof text === "string" ? <p className="text">{text}</p> : text}

				{list.length > 0 && (
					<CheckList>
						{list.map((listItem, index) => {
							return <CheckListItem key={index}>{listItem}</CheckListItem>;
						})}
					</CheckList>
				)}

				{cta}
			</div>

			<div className="twoColumns-imageWrapper">
				{customVisual ? (
					customVisual
				) : (
					<div className="twoColumns-imageWrapperInner">
						<Img fluid={img} />
					</div>
				)}
			</div>
		</div>
	);
};

export default TwoColumnSection;
